import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { catchError, of } from 'rxjs'
import {
  UsersApiGetUsersParams,
  UsersApiGetUsersResponse
} from '@services/api'

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  private readonly http: HttpClient = inject(HttpClient)

  /**
   * Get users request
   * @description Request for get users list
   * @param {UsersApiGetUsersParams} params
   */
  getUsers (params: UsersApiGetUsersParams) {
    return this.http.get<UsersApiGetUsersResponse>('v1/admin/users', {
      params: new HttpParams({
        fromObject: { ...params }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Get user by ID
   * @param {number} id
   */
  getUserById (id: number) {
    return this.http.get(`v1/admin/users/${ id }`).pipe(
      catchError(({ error }) => of(error))
    )
  }
}
