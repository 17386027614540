import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, of } from 'rxjs'

export interface AuthApiSignInParams {
  login: string
  password: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  private readonly http: HttpClient = inject(HttpClient)

  // TODO: Создать интерфейс для ответов
  /**
   * Sign in request
   * @param {AuthApiSignInParams} params
   */
  signIn (params: AuthApiSignInParams) {
    return this.http.post('/api/auth/sign-in', params).pipe(
      catchError(({ error }) => of(error))
    )
  }

  // TODO: Создать интерфейс для ответов
  /**
   * Sign out request
   */
  signOut () {
    return this.http.post('/api/auth/sign-out', {}).pipe(
      catchError(({ error }) => of(error))
    )
  }
}
