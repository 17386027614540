export enum WithdrawalRequestStatus {
  /** Created by user */
  CREATED = 1,
  /** Confirmed by manager */
  POSTED = 2,
  /** Canceled by manager */
  CANCELED = 3
}

export const WithdrawalRequestStatusLabels: Record<WithdrawalRequestStatus, string> = {
  [WithdrawalRequestStatus.CREATED]: 'Заявка',
  [WithdrawalRequestStatus.POSTED]: 'Подтвержден',
  [WithdrawalRequestStatus.CANCELED]: 'Отменен'
}

export interface WithdrawalRequest {
  /**
   * Transaction ID
   * @type {number}
   * @example 571
   */
  transaction_id: number
  /**
   * Transaction UUID
   * @type {string}
   * @example 'c092b8ae-4222-4abb-916d-a53e589a8bb3'
   */
  transaction_uuid: string
  /**
   * User ID
   * @type {number}
   * @example 61
   */
  user_id: number
  /**
   * User UUID
   * @type {string}
   * @example 'c092b8ae-4222-4abb-916d-a53e589a8bb3'
   */
  user_uuid: string
  /**
   * Currency code (symbol)
   * @type {string}
   * @example 'USDT'
   */
  currency_code: string
  /**
   * Currency code (symbol)
   * @type {string}
   * @example 'Tether'
   */
  currency_name: string
  /**
   * Currency image path
   * @type {string}
   * @example 'https://doamin.com/images/currencies/usdt.png'
   */
  coin_path: string
  /**
   * Amount
   * @type {number}
   * @example 155.00
   */
  amount: number
  /**
   * Amount in USDT
   * @type {number}
   * @example 155.00
   */
  amount_in_usdt: number
  /**
   * Address
   * @type {string}
   * @example 'TCQNDoKEM7CDsJmfizyLvKfGrCCU4e4XmW'
   */
  address: string
  /**
   * Network
   * @type {string}
   * @example 'Tron (TRC-20)'
   */
  network: string
  /**
   * Network designation
   * @type {string}
   * @example 'TRX'
   */
  network_designation: string
  /**
   * Status
   * @type {string}
   * @example 'Created'
   */
  status: string
  /**
   * Status ID
   * @type {WithdrawalRequestStatus}
   * @example 1
   */
  status_id: WithdrawalRequestStatus
  /**
   * "Is real" flag
   * @type {boolean}
   */
  is_real: boolean
  /**
   * Date of creation
   * @type {string}
   * @example '2024-09-21T12:16:35.000000Z'
   */
  created_at: string
}
