import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, of } from 'rxjs'
import { ProfileApiGetDataResponse } from './profile-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {
  private readonly http: HttpClient = inject(HttpClient)

  /**
   * Запрос данных профиля
   * @description Возвращает данные аутентифицированного пользователя
   */
  getProfileData () {
    return this.http.get<ProfileApiGetDataResponse>('v1/admin/users/auth').pipe(
      catchError(({ error }) => of(error))
    )
  }
}
