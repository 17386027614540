import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { catchError, of } from 'rxjs'
import {
  WithdrawalsApiAcceptRequestBody,
  WithdrawalsApiCancelRequestBody,
  WithdrawalsApiGetWithdrawalRequestsParams,
  WithdrawalsApiGetWithdrawalRequestsResponse
} from '@services/api'

@Injectable({
  providedIn: 'root'
})
export class WithdrawalsApiService {
  private readonly http: HttpClient = inject(HttpClient)

  /**
   * Get list of withdrawal requests
   * @param {WithdrawalsApiGetWithdrawalRequestsParams} params
   */
  getWithdrawalRequests (params: WithdrawalsApiGetWithdrawalRequestsParams) {
    return this.http.get<WithdrawalsApiGetWithdrawalRequestsResponse>('v1/admin/withdrawals', {
      params: new HttpParams({
        fromObject: { ...params }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Cancel withdrawal request
   * @param {WithdrawalsApiCancelRequestBody} body
   */
  cancelRequest (body: WithdrawalsApiCancelRequestBody) {
    return this.http.post('v1/admin/withdrawals/cancel', body).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Confirm withdrawal request
   * @param {WithdrawalsApiAcceptRequestBody} body
   */
  confirmRequest (body: WithdrawalsApiAcceptRequestBody) {
    return this.http.post('v1/admin/withdrawals/confirm', body).pipe(
      catchError(({ error }) => of(error))
    )
  }
}
