/**
 * Date control model
 * @description This model is used to control the date and check if it is stale
 * @example
 * const date = new DateControl(new Date(), 60000)
 * date.isStale() // false, 'cause must be less than 1 minute old
 * // Wait 1 minute...
 * date.isStale() // true, 'cause it is now 1 minute old
 * @example
 * const date = new DateControl(new Date(), 60000)
 * // do something...
 * date.updateDate() // update the date to now
 */
export class DateControl {
  /**
   * Actual date
   * @type {Date}
   * @private
   */
  date: Date

  /**
   * Seconds threshold for date to be considered stale
   * @private
   */
  readonly secondsThreshold: number

  /**
   * Constructor
   * @description Create a new DateControl instance
   * @param {Date} date actual date
   * @param {number} secondsThreshold milliseconds threshold for date to be considered stale
   */
  constructor (date: Date = new Date(), secondsThreshold: number = 60000) {
    this.date = date
    this.secondsThreshold = secondsThreshold
  }

  /**
   * Get the actual date
   * @description This is the date that will be checked for staleness
   * @type {Date}
   */
  getDate (): Date {
    return this.date
  }

  /**
   * Set the actual date
   * @param {Date} date
   */
  setDate (date: Date) {
    this.date = date
  }

  /**
   * Update the actual date and set it to now
   */
  updateDate () {
    this.date = new Date()
  }

  /**
   * Check if the date is stale
   * @description If the date is older than the threshold, it is considered stale
   * @returns {boolean}
   */
  isStale (): boolean {
    return this.date.getTime() < (Date.now() - this.secondsThreshold)
  }
}
