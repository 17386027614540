import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { catchError, of } from 'rxjs'
import {
  TransactionsApiCreateTransactionParams,
  TransactionsApiCreateTransactionResponse,
  TransactionsApiGetStatsParams,
  TransactionsApiGetStatsResponse,
  TransactionsApiGetTransactionsParams,
  TransactionsApiGetTransactionsResponse
} from '@services/api'

@Injectable({
  providedIn: 'root'
})
export class TransactionsApiService {
  private readonly http: HttpClient = inject(HttpClient)

  /**
   * Get transactions request
   * @param {TransactionsApiGetTransactionsParams} params
   */
  getTransactions (params: TransactionsApiGetTransactionsParams) {
    return this.http.get<TransactionsApiGetTransactionsResponse>('v1/admin/transactions', {
      params: new HttpParams({
        fromObject: { ...params }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Get transaction by ID
   * @param {number} id The ID of the transaction
   */
  getTransaction (id: number) {
    return this.http.get<TransactionsApiGetTransactionsResponse>('v1/admin/transactions', {
      params: new HttpParams({
        fromObject: {
          'filter[id]': id.toString()
        }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Get transactions statistics request
   * @description Request for calculate statistics for transactions
   * @param {TransactionsApiGetStatsParams} params
   */
  getStats (params: TransactionsApiGetStatsParams) {
    return this.http.get<TransactionsApiGetStatsResponse>('v1/admin/transactions/total', {
      params: new HttpParams({
        fromObject: { ...params }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Create a new transaction
   * @param {TransactionsApiCreateTransactionParams} params - The data for the new transaction
   */
  createTransaction (params: TransactionsApiCreateTransactionParams) {
    return this.http.post<TransactionsApiCreateTransactionResponse>('v1/admin/transactions/create', params).pipe(
      catchError(({ error }) => of(error))
    )
  }
}
