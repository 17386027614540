import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import {
  CurrenciesApiGetCurrenciesParams,
  CurrenciesApiGetCurrenciesResponse,
  CurrenciesApiGetCurrencyResponse, CurrenciesApiGetLightCurrenciesResponse
} from '@services/api'
import { catchError, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CurrenciesApiService {
  private readonly http: HttpClient = inject(HttpClient)

  /**
   * Get all currencies
   * @description Get all currencies with pagination
   * @param {CurrenciesApiGetCurrenciesParams} params
   */
  getCurrencies (params: CurrenciesApiGetCurrenciesParams): Observable<CurrenciesApiGetCurrenciesResponse> {
    return this.http.get<CurrenciesApiGetCurrenciesResponse>('v1/admin/currencies', {
      params: new HttpParams({
        fromObject: { ...params }
      })
    }).pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Get all currencies (light version)
   * @description Get only id, name, code and rate of currencies without pagination
   * @return {Observable<CurrenciesApiGetLightCurrenciesResponse>}
   */
  getLightCurrencies () {
    return this.http.get<CurrenciesApiGetLightCurrenciesResponse>('v1/admin/currencies/light').pipe(
      catchError(({ error }) => of(error))
    )
  }

  /**
   * Get currency by ID
   * @param {number} id
   * @return {Observable<CurrenciesApiGetCurrencyResponse>}
   */
  getCurrency (id: number): Observable<CurrenciesApiGetCurrencyResponse> {
    return this.http.get<CurrenciesApiGetCurrencyResponse>(`v1/admin/currencies/${ id }`).pipe(
      catchError(({ error }) => of(error))
    )
  }
}
