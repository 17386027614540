export enum TransactionTypeIds {
  DEPOSIT = 1,
  WITHDRAWAL_FUNDS_RESERVATION = 2,
  WITHDRAWAL_FUNDS_REFUND = 3,
  WITHDRAWAL = 4,
  EXCHANGE_WRITE_OFF = 5,
  EXCHANGE_DEPOSIT = 6,
  EXCHANGE_PROFIT = 7
}

/**
 * Transaction agent
 * @description Can be as sender or receiver
 * @example 'user'
 */
export enum TransactionAgent {
  USER = 'user',
  COMPANY = 'company'
}

export interface TransactionStatsData {
  /**
   * Total sum of transactions, by rate in USDT
   * @example 1919398.02
   */
  sum_in_usdt: number
  /**
   * Count of transactions
   * @example 519
   */
  count: number
  /**
   * Average transaction amount
   * @example 3698
   */
  avg: number
}

export interface Transaction {
  /**
   * ID
   * @description Unique identifier of transaction, autoincrement.
   * @type {number}
   * @example 1
   */
  id: number
  /**
   * UUID
   * @description UUID is a unique identifier of transaction for users and managers.
   * @type {string}
   * @example 'd290f1ee-6c54-4b01-90e6-d701748f0851'
   */
  uuid: string
  /**
   * Transaction type ID
   * @type {number}
   */
  transaction_type_id: number
  /**
   * Transaction type name
   * @type {string}
   */
  transaction_type: string
  /**
   * Currency name
   * @type {string}
   * @example 'Tether'
   */
  currency_name: string
  /**
   * Currency symbol
   * @type {string}
   * @example 'USDT'
   */
  currency_code: string
  /**
   * Currency icon
   * @type {string}
   * @example 'https://example.com/icon.png'
   */
  currency_icon: string
  /**
   * Amount of transaction
   * @type {number}
   * @example 10.05
   */
  amount: number
  /**
   * Amount of transaction by rate in USDT
   * @type {number}
   * @example 593.32
   */
  amount_in_usdt: number
  /**
   * Sender type
   * @type {TransactionAgent}
   * @example 'user'
   */
  sender_type: TransactionAgent
  /**
   * Sender ID
   * @description Can be only for users
   * @type {number | null}
   */
  sender_id: number | null
  /**
   * Sender UUID
   * @description Can be only for users
   * @type {string | null}
   */
  sender_uuid: string | null
  /**
   * Receiver type
   * @type {TransactionAgent}
   * @example 'user'
   */
  receiver_type: TransactionAgent
  /**
   * Receiver ID
   * @description Can be only for users
   * @type {number | null}
   */
  receiver_id: number | null
  /**
   * Receiver UUID
   * @description Can be only for users
   * @type {string | null}
   */
  receiver_uuid: string | null
  /**
   * Status of transaction as real or not
   * @type {string}
   * @example 'success'
   */
  is_real: boolean
  /**
   * Created at date
   * @type {string}
   * @example 'success'
   */
  created_at: string
  /**
   * Deleted at date
   * @type {string}
   * @example 'success'
   */
  deleted_at: string
}

