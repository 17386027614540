export type MessageText = string | undefined

export enum MessageSeverity {
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
  Secondary = 'secondary',
  Contrast = 'contrast'
}

/**
 * Сообщение
 *
 * @param text: string сообщение
 * @param severity: MessageSeverity уровень важности сообщения
 *
 * @example
 * ```ts
 * const msg = new Message('Сообщение', 'info')
 * msg.success('Успешное сообщение')
 * msg.clear()
 * ```
 *
 * ```html
 * <ui-message [text]="msg.text" [severity]="msg.severity" />
 * ```
 */
export class Message {
  #text: MessageText
  get text (): MessageText {
    return this.#text
  }

  #severity: MessageSeverity
  get severity (): MessageSeverity {
    return this.#severity
  }

  constructor (text: MessageText, severity: MessageSeverity = MessageSeverity.Info) {
    this.#text = text
    this.#severity = severity
  }

  /** Создать сообщение с уровнем важности */
  update (text: string, severity?: MessageSeverity) {
    this.#text = text
    if (severity) this.#severity = severity
  }

  /** Создать сообщение с уровнем важности 'success' */
  success (text: string) {
    this.update(text, MessageSeverity.Success)
  }

  /** Создать сообщение с уровнем важности 'info' */
  info (text: string) {
    this.update(text, MessageSeverity.Info)
  }

  /** Создать сообщение с уровнем важности 'warn' */
  warn (text: string) {
    this.update(text, MessageSeverity.Warn)
  }

  /** Создать сообщение с уровнем важности 'error' */
  error (text: string) {
    this.update(text, MessageSeverity.Error)
  }

  /** Создать сообщение с уровнем важности 'secondary' */
  secondary (text: string) {
    this.update(text, MessageSeverity.Secondary)
  }

  /** Создать сообщение с уровнем важности 'contrast' */
  contrast (text: string) {
    this.update(text, MessageSeverity.Contrast)
  }

  /** Очистить сообщение */
  clear () {
    this.#text = undefined
  }

  /** Сбросить сообщение */
  reset () {
    this.#text = undefined
    this.#severity = MessageSeverity.Info
  }
}
