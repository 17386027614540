export enum UserRole {
  Root = 'root',
  Admin = 'admin',
  Manager = 'manager',
  User = 'user'
}

export const UserRoleList = {
  [UserRole.Root]: 'Super Admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Manager]: 'Manager',
  [UserRole.User]: 'User'
}

/** User model */
export interface User {
  /**
   * ID
   * @type {number}
   * @example 1
   */
  id: number
  /**
   * Name of the wallet
   * @type {string}
   * @example "Main Wallet"
   */
  name: string
  /**
   * UUID
   * @type {string}
   * @example "f7b3b1b0-0b7b-4b7b-8b7b-7b7b7b7b7b7b"
   */
  uuid: string
  /**
   * Seed-phrase
   * @description Seed-phrase is a string that is used to generate a private key
   * @type {string}
   * @example "apple juice dog tree ..."
   */
  seed: string
  /**
   * Status "not a fake"
   * @description This status used to determine if the user is real or not
   * @type {boolean}
   * @example false
   */
  is_real: boolean
  /**
   * Main balance in USDT
   * @type {number | null}
   * @example 100.00
   */
  main_balance_in_usdt: number | null
  /**
   * Request withdrawal balance in USDT
   * @type {number | null}
   * @example 100.00
   */
  request_withdrawal_balance_in_usdt: number | null
  /**
   * Date and time when the user was registered
   * @type {string | null}
   * @example "2021-09-29T14:00:00.000Z"
   */
  deleted_at: string | null
  /**
   * Date and time when the user was registered
   * @type {string}
   * @example "2021-09-29T14:00:00.000Z"
   */
  created_at: string
  /**
   * Comment
   * @type {string}
   * @example "Some comment"
   */
  comment: string
  /**
   * User roles
   * @type {UserRole[]}
   * @description Roles of the user
   * @example ["manager"]
   */
  roles: UserRole[]
  /**
   * Seed-phrase
   * @type {string}
   * @description User's seed-phrase which is used to generate a private key for the wallet and get access to the wallet
   * @example "apple juice dog tree ..."
   */
  seed_phrase: string
}
