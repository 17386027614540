export enum ResponseStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface BaseResponse <T = unknown> {
  status: ResponseStatus
  message: string
  data?: T
}

export interface PaginationParams {
  /**
   * Pagination page size
   * @example 10
   */
  'page[size]'?: number
  /**
   * Pagination page number
   * @example 1
   */
  'page[number]'?: number
}

export interface Pagination {
  current_page: number
  per_page: number
  from: number
  to: number
  total: number
}
